<script setup>
import WebsiteLayout from '@/Layouts/WebsiteLayout.vue';
import RestaurantHead from '@/Components/Shared/Restaurant/RestaurantHead.vue';
import Container from '@/Components/Shared/Container.vue';
import { Link } from '@inertiajs/vue3';
import { computed } from 'vue';
import route from '@/Helpers/route';
import HomepageSlider from '@/Components/Shared/Restaurant/HomepageSlider.vue';
import CardTitle from '@/Components/Shared/Cards/CardTitle.vue';
import Card from '@/Components/Shared/Cards/Card.vue';
import NewsletterForm from '@/Components/Website/NewsletterForm.vue';

const props = defineProps({
    honeypot: Object,
    restaurant: Object,
    page: Object,
    slides: Array,
    images: Array,
    articles: Array,
    schema: String,
});

const text = computed(() => props.page.blocks.find(x => x.slug === 'text')?.content_html ?? '');
const firstExtra = computed(() => props.page.blocks.find(x => x.slug === 'extra-1')?.content_html ?? '');
const secondExtra = computed(() => props.page.blocks.find(x => x.slug === 'extra-2')?.content_html ?? '');
const bottom = computed(() => props.page.blocks.find(x => x.slug === 'bottom')?.content_html ?? '');

const mapsQuery = computed(() => {
    let address = encodeURIComponent(`${props.restaurant.name}, ${props.restaurant.address.street} ${props.restaurant.address.number}, ${props.restaurant.address.zipcode} ${props.restaurant.address.city}`);

    let query = 'https://maps.google.com/maps?';

    let params = [
        `q=${address}`,
        '&t=',
        '&z=16',
        '&ie=UTF8',
        '&iwloc=',
        '&output=embed',
    ];

    return query + params.join('');
});
</script>

<template>
    <RestaurantHead :restaurant="restaurant" :schema="schema" />

    <WebsiteLayout :restaurant="restaurant">
        <main>
            <section class="mt-0.5 flex flex-row justify-start bg-gray-100" v-if="slides.length > 0">
                <Container class="py-8 md:py-24">
                    <HomepageSlider :slides="slides" />
                </Container>
            </section>

            <section class="mt-0.5 flex flex-row justify-start bg-gray-100" v-if="slides.length === 0">
                <Container class="grid grid-cols-1 xl:grid-cols-2 gap-4 py-8 md:py-24">
                    <div class="order-last xl:order-first">
                        <h1 class="font-bold text-[48px] md:text-[58px] text-[var(--primary)] leading-tight leading-none mb-2">{{ restaurant.name }}</h1>
                        <div v-html="text" class="cms-css mb-8" v-if="text"></div>

                        <div class="2xl:w-1/2 grid grid-cols-2 gap-12">
                            <Link :href="route('website.menu', { restaurant })" class="
                                flex w-full items-center justify-center
                                cursor-pointer font-medium
                                rounded-xl transition ease-in-out duration-150
                                bg-[var(--primary)] text-white p-4
                            " v-if="restaurant.can_deliver || restaurant.can_pickup">
                                Direct bestellen
                            </Link>

                            <Link :href="route('website.reservations.create', { restaurant })" class="
                                flex w-full items-center justify-center
                                cursor-pointer font-medium
                                rounded-xl transition ease-in-out duration-150
                                bg-[var(--accent)] text-white p-4
                            " v-if="restaurant.accepts_reservations">
                                Direct reserveren
                            </Link>
                        </div>
                    </div>

                    <img
                        :src="restaurant.links.rectangle_banner"
                        :srcset="restaurant.links.responsive_rectangle_banner"
                        :alt="restaurant.name"
                        class="w-full rounded-xl shadow-md object-cover order-first xl:order-last aspect-video">
                </Container>
            </section>

            <section class="bg-white shadow-sm py-24">
                <Container>
                    <div class="grid grid-cols-2 xl:grid-cols-4 gap-4 mb-24">
                        <a :href="image.links.image" target="_blank" v-for="image in images" :key="image.id">
                            <img :src="image.links.image" :srcset="image.links.responsive_image" :alt="image.description" class="w-full rounded-xl shadow-md">
                        </a>
                    </div>

                    <div class="grid grid-cols-1 md:grid-cols-2 gap-24 mb-12">
                        <div v-html="firstExtra" class="cms-css mb-8" v-if="firstExtra"></div>
                        <div v-html="secondExtra" class="cms-css mb-8" v-if="secondExtra"></div>
                    </div>

                    <div class="2xl:w-1/2 grid grid-cols-2 gap-12 mx-auto">
                        <Link :href="route('website.menu', { restaurant })" class="
                            flex w-full items-center justify-center
                            cursor-pointer font-medium
                            rounded-xl transition ease-in-out duration-150
                            bg-[var(--primary)] text-white
                            p-4
                        " v-if="restaurant.can_deliver || restaurant.can_pickup">
                            Direct bestellen
                        </Link>

                        <Link :href="route('website.reservations.create', { restaurant })" class="
                            flex w-full items-center justify-center
                            cursor-pointer font-medium
                            rounded-xl transition ease-in-out duration-150
                            bg-[var(--accent)] text-white
                            p-4
                        " v-if="restaurant.accepts_reservations">
                            Direct reserveren
                        </Link>
                    </div>
                </Container>
            </section>

            <section class="py-24">
                <Container class="max-w-[1000px]!">
                    <h2 class="block text-gray-700 font-bold text-[26px] md:text-[32px] mb-4" v-if="articles.length > 0">Recente nieuws updates</h2>

                    <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-8">
                        <div class="flex flex-col" v-for="article in articles" :key="article.id" :dusk="`article-${article.id}`">
                            <Link :href="route('website.news.show', { restaurant, slug: article.slug })">
                                <img :src="article.links.image" :srcset="article.links.responsive_image" :alt="article.title" class="w-full aspect-video object-cover rounded-t-xl shadow-md my-auto">
                            </Link>

                            <Card class="rounded-t-none">
                                <div class="p-3">
                                    <CardTitle>{{ article.title }}</CardTitle>

                                    <div v-html="article.preview_html ?? ''" class="cms-css mb-4"></div>

                                    <div class="flex flex-row justify-between items-center mt-auto">
                                        <Link :href="route('website.news.show', { restaurant, slug: article.slug })" class="
                                            flex items-center justify-center
                                            cursor-pointer font-medium text-sm
                                            rounded-xl transition ease-in-out duration-150
                                            bg-[var(--primary)] text-white px-4 py-2
                                        ">
                                            Lees meer
                                        </Link>

<!--                                        <span class="font-medium text-sm text-gray-700">{{ article.visible_start.formatted }}</span>-->
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>

                    <div v-html="bottom" class="cms-css mb-8" v-if="bottom"></div>
                </Container>
            </section>

            <section>
                <iframe class="w-full min-h-[450px]" :src="mapsQuery" :title="`Google Maps Navigatie - ${restaurant.name}`"></iframe>
            </section>

            <section class="py-24" v-if="restaurant.mailchimp_settings.is_enabled">
                <NewsletterForm
                    :restaurant="restaurant"
                    :honeypot="honeypot" />
            </section>
        </main>
    </WebsiteLayout>
</template>
