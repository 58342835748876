<script setup>
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import { register } from 'swiper/element/bundle';

register();

defineProps({
    slides: Array,
});
</script>

<template>
    <swiper-container
        :slides-per-view="1"
        :space-between="50"
        :autoplay="{ delay: 1_000 }"
        :modules="[Autoplay]"
        :loop="true"
    >
        <swiper-slide v-for="slide in slides" :key="slide.id">
            <div class="grid grid-cols-1 xl:grid-cols-2 gap-4">
                <div>
                    <h1 class="font-bold text-[48px] md:text-[58px] text-[var(--primary)] leading-tight mb-2">{{ slide.title }}</h1>
                    <div v-html="slide.content_html" class="cms-css mb-8" v-if="slide.content_html"></div>

                    <div class="2xl:w-2/3 grid grid-cols-2 gap-12">
                        <a :href="slide.first_link" class="
                            flex w-full items-center justify-center
                            cursor-pointer font-medium
                            rounded-xl transition ease-in-out duration-150
                            bg-[var(--primary)] text-white p-4
                        " v-if="slide.first_link && slide.first_link_text">
                            {{ slide.first_link_text }}
                        </a>

                        <a :href="slide.second_link" class="
                            flex w-full items-center justify-center
                            cursor-pointer font-medium
                            rounded-xl transition ease-in-out duration-150
                            bg-[var(--primary)] text-white p-4
                        " v-if="slide.second_link && slide.second_link_text">
                            {{ slide.second_link_text }}
                        </a>
                    </div>
                </div>

                <img v-if="slide.links.image" :src="slide.links.image" :srcset="slide.links.responsive_image" :alt="slide.title" class="w-full shadow-sm rounded-lg object-cover order-first lg:order-last">
                <iframe v-else :src="slide.embed_url" class="w-full shadow-sm rounded-lg aspect-video order-first lg:order-last" allowfullscreen></iframe>
            </div>
        </swiper-slide>
    </swiper-container
        :slides-per-view="1"
        :space-between="50"
        :autoplay="{ delay: 1_000 }"
        :modules="[Autoplay]"
        :loop="true">
</template>
